<template>
    <div class="ListGame">
        <div class="header-games">
            <span>
                <router-link :to="{name: 'GameType'}">
                    <i class="fa fa-chevron-left"></i> 
                </router-link>
            </span>
            <img src="@/assets/images/casino/logo-sexy-baccarat.png"/>
            <div class="box-list-display">
                <span @click="displayMode = 'thumbnail'"
                      class="mr-2"
                      :class="{'active': displayMode === 'thumbnail'}">
                    <i class="fas fa-bars"></i>
                </span>
                <span @click="displayMode = 'list'"
                      :class="{'active': displayMode === 'list'}">
                    <i class="fas fa-th-large"></i>
                </span>
            </div>
        </div>
        <!--<MenuBar mode="top-only" @toggleMenu="openLeftMenu"/>-->
        <transition name="slide">
            <MenuLeft @close="closeLeftMenu" v-show="showMenuLeft"></MenuLeft>
        </transition>
        <div class="content-page">
            <div class="wrapper-box">
                <div v-for="(table, key) of tableList"
                     :key="key"
                     @click="play(table)"
                     class="box-game"
                     :class="{'box-list-games': displayMode === 'list'}">
                    <span>เล่นเกมส์</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    // import MenuBar from '@/components/MenuBar'
    import MenuLeft from '@/components/MenuLeft'
    import LayoutMixin from '@/mixins/Layout'
    import GameMixin from '@/mixins/Game'

    export default {
        name: 'ListGame',
        components: {
            // MenuBar,
            MenuLeft,
        },
        mixins: [
            LayoutMixin,
            GameMixin,
        ],
        data() {
            return {
                displayMode: 'thumbnail', //list , thumbnail
            }
        },
        computed: {
            queryType() {
                return this.$route.query.type
            },
            tableList() {
                return this.getTableListByType(this.queryType)
            },
        },
        methods: {
            play(table) {
                this.selectGameTable(table)
            },
        }
    }
</script>
<style scoped>
    .box-list-display {
        position: absolute;
        top: 50px;
        right: 20px;
        margin-bottom: 5px;
        color: #fff;
    }

    .box-list-display span:hover, .box-list-display span.active {
        color: #d32ce6;
    }

    .content-page {
        /*height: calc(100vh - 60px);*/
        height: 90vh;
        background-color: #fff;
        border-radius: 20px 20px 0px 0px;
        margin-top: -15px;
        padding: 15px;
    }

    .header-games {
        padding: 10px;
        height: 95px;
        background-image: url(../assets/images/default-background.jpg);
        text-align: center;
        color: #fff;
    }

    .header-games span {
        display: inline-block;
        float: left;
    }

    .header-games span a {
        color: #fff;
    }

    .header-games img {
        width: 80px;
    }

    .box-game {
        position: relative;
        background-image: url(../assets/images/casino/sexy-baccarat/sexy-bacarat.png);
        background-position: center;
        background-size: cover;
        height: 160px;
        margin-bottom: 15px;
    }

    .box-game span {
        display: inline-block;
        background-color: #fff;
        padding: 5px 10px;
        position: absolute;
        bottom: 0;
        right: 0;
        border-radius: 10px 0px 0px 0px;
        font-family: "Athiti", sans-serif;
        font-weight: 600;
    }

    .box-list-games {
        display: inline-block;
        width: 45%;
        height: 90px;
        margin: 8px 8px 0px 8px;
    }

    .box-list-games span {
        font-size: 14px;
        padding: 3px 10px;
    }


</style>